<template>
  <main class="page-projects page-edit-project p-0 d-flex flex-column">
    <ul class="nav nav-pills nav-fill">
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'projects.show' }">Dati</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link active" :to="{ name: 'projects.edit' }" aria-current="page">Modifica</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'projects.visea' }">Modulo ViSEA</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'projects.visea.graph' }">Modulo ViSEA (Grafo)</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'projects.tutorial' }">Tutorial</router-link>
      </li>
    </ul>
    <div class="flex-grow-1 container pt-3">
      <ProjectForm :feedback.sync="feedback" :project="projectToEdit" @submitForm="submit" edit />
    </div>
  </main>
</template>

<script>

export default {
  name: 'project-edit',
  components: {
    ProjectForm: () => import('@/views/components/Form/ProjectForm'),
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      projectToEdit: {},
      feedback: null,
    };
  },
  methods: {
    updateProjectToEdit (project) {
      this.projectToEdit = {
        ...project,
        companyId: project?.company?.id || null,
        clusterId: project?.cluster?.id || null,
        ...this.projectToEdit,
      };
    },
    submit (projectToEdit) {
      const project = { ...projectToEdit };

      this.$api.updateProject(project.id, project)
        .then(res => {
          this.feedback = true;
          this.$emit('update:project', res.data);
          this.projectToEdit = {};
          this.$router.push({ name: 'projects.show' });
        })
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
  },
  mounted () {
    this.updateProjectToEdit(this.project);
  },
  watch: {
    project (newValue) {
      this.updateProjectToEdit(newValue);
    },
  },
};

</script>
